.login__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(to bottom, #ffbb3e 0%,#1a1b1e 92%);
  height: 100vh;
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    content: '';
    background: url("../../gfx/login-background.png") no-repeat;
    z-index: 100;
  }
  .login__card {
    border-radius: $border-radius;
    padding: 1rem;
    width: 300px;
    background-color: $white;
    box-shadow: $box-shadow;
    z-index: 101;
  }
  .register {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ddd;
    line-height: 0.1em;
    margin: 12px 0 20px;
    span {
      font-weight: 200;
      font-size: 16px;
      background:#fff;
      padding:0 10px;
    }
  }
  h1 {
    font-weight: 700;
  }
  .input-group.input-group-lg {
    span.input-group-text {
      border-right: none;
      background-color: $light;
    }
    input.form-control {
      border-left: none;
      background-color: $light;
    }
    &:focus-within {
      input {
        border-top-color: lighten($primary, 25%) !important;
        border-right-color: lighten($primary, 25%) !important;
        border-bottom-color: lighten($primary, 25%) !important;
      }
      span {
      border-top-color: lighten($primary, 25%) !important;
      border-left-color: lighten($primary, 25%) !important;
      border-bottom-color: lighten($primary, 25%) !important;
      }
    }
  }
  .form-check {
    margin-left: .875rem;
  }
  .cant-access {
    font-size: .625rem;
  }
}

@media only screen and (max-width: 600px) {
  .login__container {
    min-height: 100vh;
    height: auto;
  }
}
