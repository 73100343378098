.preview-modal {
  .modal-content {
    top: 3vh;
    left: initial;
    height: 90vh;
    width: 62.5vw;
  }
  .modal-body {
    height: auto;
    overflow-y: hidden;
  }

  .preview-container {
    width: 100%;
    .preview-image {
      width: 100%;
      object-fit: contain;
      //noinspection CssInvalidPropertyValue
      image-rendering: -webkit-optimize-contrast;
      transform-origin: top left;
      &.rotate-90 {
        transform: rotate(90deg) translateY(-100%);
      }
      &.rotate-180 {
        transform: rotate(180deg) translate(-100%,-100%);
      }
      &.rotate-270 {
        transform: rotate(270deg) translateX(-100%);
      }
      &.rotate-360, .rotate-0 {
        transform: rotate(0deg);
      }
    }
  }
}

.modal-grid {
  display: grid!important;
  margin: 0 !important;
  width: 100vw;
  max-width: 100vw;
  grid-template-rows: 1fr minmax(auto, 95vh) 1fr;
  grid-template-columns: 1fr 6fr 1fr;
  grid-template-areas:
    '. . .'
    '. content .'
    '. . .';
  gap: 1rem;

  .modal-content {
    grid-area: content;
    position: relative!important;
    top: 0!important;
    left: 0!important;
    width: 100%;
    height: 100%;
  }
  .preview-container {
    width: 100%;

    .preview-image {
      width: 100%;
      object-fit: contain;
      //noinspection CssInvalidPropertyValue
      image-rendering: -webkit-optimize-contrast;
      transform-origin: top left;
      &.rotate-90 {
        transform: rotate(90deg) translateY(-100%);
      }
      &.rotate-180 {
        transform: rotate(180deg) translate(-100%,-100%);
      }
      &.rotate-270 {
        transform: rotate(270deg) translateX(-100%);
      }
      &.rotate-360, .rotate-0 {
        transform: rotate(0deg);
      }
    }
  }
}

.excel-preview-container {
  height: calc(100vh - 190px)
}
