.navigation-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow;
  z-index: 49;
  @include media-breakpoint-down(md) {
    h2 {
      font-size: 0.75rem;
    }
    .btn {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .btn {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  }

  .navigation-route {
    border-right: $border-width $border-color solid;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
    &:hover {
      border-right: $border-width $border-color solid;
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
    }
  }
  .navigation-route:last-child {
    border-right: inherit;
  }
}
.new-declaration__container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: calc(100vh - 60px - 7.5rem); // remaining = max - header - (topbar+navigation bar)
  width: 100%;
  @include media-breakpoint-down(md) {
    .no-mobile-border {
      border: none !important;
    }
  }
  @include media-breakpoint-down(sm) {
    height: calc(100vh - 60px - 11.5rem);
  }
  &--notification-open {
    height: calc(100vh - 120px - 7.5rem); // remaining = max - header with notification bar - (topbar+navigation bar)
  }
}

.dropdown-area {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed $light;
  width: 100%;
  height: 100%;
  min-height: 3rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  transition: border-color,background-color 200ms;
  &.file-hover {
    background-color: lighten($orange-100, 14);
  }
  &:hover, &.file-hover {
    border: 2px dashed $gray-200;
    transition: border-color,background-color 200ms;
  }
}
.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid $gray-400;
  padding-bottom: 0.25rem;
  i.text-black-50 {
    transition: color 0.15s ease-in-out;
    &:active,
    &:focus,
    &:hover {
      color: $primary !important;
    }
  }
}

.heading-button {
  position: absolute;
  top: -8px;
  right: 0;
  font-size: .80rem !important;
}
.item-hover {
  &--active {
    background-color: lighten($primary, 28%);
  }
  &:active,
  &:focus,
  &:hover {
    background-color: lighten($primary, 25%);
  }
  .cursor-pointer:hover {
    cursor: pointer;
  }
}
.hover-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: $white;
}

// Good items:
.items-list {
  box-shadow: $box-shadow-inset-left;
  padding-top: 1.5rem;
  height: auto;
  background-color: $light;
  overflow: scroll;
  top: 0;
  .items-list__header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem .25rem;
    border-bottom: 1px solid $gray-400;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }
  .items-list__row {
    border-bottom: 1px solid $gray-400;
    .items-list__row-title  {
      padding-top: .25rem;
      padding-bottom: .25rem;
      text-transform: uppercase;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }
    .items-list__row-value  {
      padding-bottom: .5rem;
      font-weight: 400;
      line-height: 1.25;
    }
  }
}

.goods-table {
  max-width: 100%;
}
// Documents:
.search {
  margin-bottom: 1.5rem;
  width: 33%;
  @include media-breakpoint-down(md) {
  width: initial;
  }
  input {
    border-right: none;
  }
  button {
    border-top-color: $gray-400 !important;
    border-right-color: $gray-400 !important;
    border-bottom-color: $gray-400 !important;
    border-radius: $border-radius-sm;
  }
  &:focus-within {
    input {
      border-top-color: lighten($primary, 25%) !important;
      border-left-color: lighten($primary, 25%) !important;
      border-bottom-color: lighten($primary, 25%) !important;
    }
    button {
      border-top-color: lighten($primary, 25%) !important;
      border-right-color: lighten($primary, 25%) !important;
      border-bottom-color: lighten($primary, 25%) !important;
    }
  }
}
