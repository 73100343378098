.select {
  position: static;
  min-width: 90px;
  font-size: .8rem;
  transition-duration: .25s;
  border-radius: .125rem;
  border: 1px solid $gray-400;
  background-color: $white;

  &:focus-within {
    border: 1px solid lighten($primary, 25%);
  }

  &.select--no-focus:focus-within {
    border: none;
  }

  .select__control {
    border-radius: 0;
    border: none;
    padding: 0 $input-padding-x;
    width: 100%;
    height: 1.75rem;
    min-height: initial;
    background-color: transparent;
    color: $gray-900;
    text-align: left;
    transition-duration: .25s;
    height: add($input-btn-line-height * $input-btn-font-size, $input-padding-y * 2);

    &:hover {
      cursor: pointer;
    }

    &--is-focused {
      box-shadow: none;

      .select__placeholder, .select__single-value {
        color: $gray-900;
      }
    }

    &--is-disabled {
      box-shadow: none;

      .select__input > input {
        visibility: visible;
      }

      .select__placeholder, .select__single-value {
        color: $gray-600;
      }
    }

    .select__value-container {
      padding: 0;
      max-width: 23rem !important;
      color: $gray-900;

      .select__placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .select__placeholder,
      .select__single-value {
        margin: 0;
        width: 100%;
        max-width: 23rem !important;
        font-weight: 300;
        cursor: pointer;
        overflow-x: hidden;
      }

      & div .select__input {
        transform: translateX(-2px);
        opacity: 1 !important;
      }
    }

    .select__indicators .select__dropdown-indicator {
      mask: url("../../icons/chevron-down.svg") no-repeat center/contain;
      background-color: $dark;
      padding: 0;
      height: 14px;
      width: 12.25px;
    }

    .select__indicators {
      display: flex;
      align-items: center;
      height: 100%;

      .select__dropdown-indicator {
        mask: url("../../icons/chevron-down.svg") no-repeat center/contain;
        background-color: $dark;
        padding: 0;
        height: 14px;
        width: 16px;

        svg {
          display: none;
        }
      }
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicators .select__clear-indicator {
      position: relative;
      top: 1px;
      margin-right: .25rem;
      padding: 0;
      width: .8rem;
      height: .8rem;
      content: '';
      mask: url("../../icons/times.svg") center no-repeat;
      background-color: $gray-700;
      transition: $transition-base;

      svg {
        display: none;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }

  .select__control--menu-is-open .select__indicators .select__dropdown-indicator {
    transform: rotate(180deg);
  }

  .select__menu {
    border-radius: .125rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    z-index: 10;

    .select__menu-list {
      border: none;
      border-radius: $border-radius-sm;
      padding: 0;
      width: fit-content;
      max-width: 20rem;

      background-color: $white;
      overflow-x: hidden;
      box-shadow: $box-shadow;
      @include media-breakpoint-down(sm) {
        width: initial;
      }

      .select__menu-notice {
        text-align: left;
        white-space: normal;
      }

      .select__option {
        width: 100%;
        font-weight: 400;
        color: $gray-800;
        text-align: left;
        background-color: $white;
        white-space: normal;
        transition-duration: .25s;

        &:focus,
        &:hover {
          background-color: $light;
          color: $gray-900;
          cursor: pointer;
        }
      }

      .select__option--is-selected {
        background-color: $primary;
        color: $gray-900;
        transition-duration: .25s;

        &:focus,
        &:hover {
          background-color: darken($primary, 15%);
          color: $secondary;
        }
      }
    }
  }

  &--is-disabled {
    background-color: $input-disabled-bg;
    border-color: $input-border-color;
  }

  &:hover {
    background-color: $gray-100;
  }
}

.select {
  &.menu-list--lg {
    .select__menu-list {
      width: max-content;
      max-width: 40rem;
    }
  }
  &.menu-list--auto {
    .select__menu-list {
      width: auto;
      max-width: 40rem;
    }
  }

  &.invalid {
    border-color: $form-feedback-invalid-color;
  }
}


//Small select modifier class styles:
.select.select-sm {
  min-width: initial;

  .select__control {
    justify-content: initial;
    border-radius: 0;

    &:focus,
    &:hover {
      background-color: $gray-500;
    }

    .select__value-container {
      width: fit-content;

      .select__placeholder,
      .select__single-value {
        position: relative;
        top: 9px;
        margin-right: 0;
        width: fit-content;
        text-overflow: initial;
        overflow: initial;

        &::after {
          content: url("../../icons/chevron-down.svg");
          margin-left: .25rem;
        }
      }
    }

    .select__indicator-separator,
    .select__indicators .select__indicator {
      display: none;
    }
  }

  .select__menu .select__menu-list .select__option--is-selected {
    background-color: $primary;

    &:focus,
    &:hover {
      background-color: darken($primary, 15%);
    }
  }
}

.registration__select {
  .select__value-container {
    padding: 0.6rem 0.5rem !important;
  }
}
// Use this fix when select input upon writing slides from left
.select.no-transition {
  .select__control .select__value-container {
    * {
      transition: none;
    }
  }
}
.import-csv-select {
  min-width: 8rem;
}
