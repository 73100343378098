.table__container {
  margin: 0.5rem 0 0;
  min-height: calc(100vh - 141px);
}
.declaration-table__container, .customer-table__container {
  height: calc(100vh - 60px - 7rem);
  min-height: unset;
  +.table-pagination {
    height: 2.75rem;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    background-color: $white;
  }
}
@include media-breakpoint-down(sm) {
  .declaration-table__container, .customer-table__container {
    height: auto;
    +.table-pagination {
      height: 3.75rem;
      position: relative;
    }
  }
}
.notification {
  &--open {
    height: calc(100vh - 120px - 7rem);
  }
  &--close {
    height: calc(100vh - 60px - 7rem);
  }

  @include media-breakpoint-down(sm) {
    &--open,
    &--close {
      height: auto;
    }
  }
}


@include media-breakpoint-down(sm) {
  .table-responsive.not-in-mobile {
    overflow-x: hidden;
  }
}

.table {
  thead {
    th {
      border-bottom: none;
      font-size: 0.875rem;
      font-weight: 400;
      white-space: nowrap;
      @include media-breakpoint-down(sm) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }

    tr.table-search .hover input{
      transition-duration: 0.25s;
      &:active,
      &:focus,
      &:hover {
        background-color: $light;
      }
    }
    tr.table-search td {
      padding: 0;
      border: 1px solid $gray-500 !important;
      vertical-align: middle;
      text-align: center;
      @include media-breakpoint-down(sm) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
      input[type="search"] {
        appearance: none;
        border: none;
        height: 32px;
        padding: 0 0.25rem;
      }
      &.cell-id {
        width: 3.85rem;
        input {
          padding-left: 1rem;
          padding-right: .25rem;
          &::-webkit-search-cancel-button {
            align-self: center;
          }
        }
      }
      &.cell-modified {
        min-width: 6.65rem;
        input {
          padding-left: .9rem;
          padding-right: .25rem;
        }
      }
      .filter-select {
        min-width: 140px;
        padding-top: 0;
        padding-bottom: 0;
        @include media-breakpoint-down(sm) {
          min-width: initial;
        }
      }
      .select .select__control {
        min-width: 8rem;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
    }
  }
  tbody {
    td {
      min-width: 4rem;
      min-height: 30px;
      height: 30px;
      max-height: 30px;
      font-weight: 300;
      font-size: 0.8rem;
      white-space: nowrap;
      @include media-breakpoint-down(sm) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
    .dropdown-cell {
      min-width: 52px;
      .dropdown {
        position: static;
        button {
          position: relative;
        }
      }
    }

    tr[role="row"]:last-child {
      td {
        border-bottom-color: $light;
      }
    }
  }
  .loading-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    backdrop-filter: blur(3px);
    filter: opacity(0.25) brightness(1.25);
    background: $white;
    &:hover {
      cursor: progress;
      color: unset!important;
      --bs-table-accent-bg: unset;
    }
  }

  .backdrop- {
    &hidden {
      transition: 250ms visibility ease-in-out;
      visibility: hidden;
    }

    &visible {
      transition: 250ms visibility ease-in-out;
      visibility: visible;
    }
  }
}

.table {
  .th,
  .td {
    padding: 5px;
    border-bottom: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    background-color: $white;
    overflow: hidden;

    :last-child {
      border-right: 0;
    }
  }

  &.sticky {
    border-collapse: separate;
    border-spacing: 0;
    overflow: scroll;
    & thead {
      tr.table-search td {
        border-right: unset!important;
      }
      th {
        position: sticky;
        top: 0;
        background-color: $white;
        height: 2.0rem;
        z-index: 5!important;
        &:nth-child(-n+2), {
          z-index: 6!important;
        }
      }
      td {
        position: sticky;
        top: 2.3rem;
        background-color: $white;
        z-index: 5!important;
        &:nth-child(-n+2), {
          z-index: 6!important;
        }
      }
    }
    .body {
      position: relative;
      z-index: 0;
    }
    td, th {
      padding: .5rem 1rem;
    }
    td {
      border-bottom-color: $gray-400;
      border-bottom-width: 1px;
    }
    tr {
      vertical-align: middle;
      &:hover {
        td[data-sticky-td]  {
          box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        }
      }
    }

    td[data-sticky-td], th[data-sticky-td]{
      background-color: $white !important;
    }

    [data-sticky-td] {
      position: sticky;
    }
    [data-sticky-last-left-td] {
      box-shadow: 2px 0 2px $gray-500;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px $gray-500;
    }
  }
}

.table-sm {
  thead tr {
    border-bottom: 1px solid $light;
    th {
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
    }
  }
  tbody {
    th {
      font-size: $font-size-sm;
      font-weight: 400;
      text-align: right;
      vertical-align: top;
      padding-top: 0.65rem;
      padding-left: 0;
      padding-right: 0.8rem;
    }
    td {
      font-weight: 400;
      vertical-align: middle;
    }
  }
  &.data-table {
    thead tr {
      border: none !important;
      th {
        color: rgba(0, 0, 0, 0.5);
        padding: 0 0 0.25rem !important;
        font-size: 0.8rem;
        text-transform: uppercase;
        div {
          border-bottom: 1px solid $light;
          padding: 0 0.25rem 0.25rem;
          width: 100%;
          height: 100%;
        }
      }
    }
    tbody tr {
      th,
      td {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        line-height: 1.25;
        vertical-align: top;
        height: initial;
      }
    }
  }
}

.loading-backdrop-message {
  position: absolute;
  top: 42%;
  width: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: progress;
}

.table-pagination {
  button:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
}
