.form-control {
  text-overflow: ellipsis;
  color: $dark;
  padding: .3rem .875rem;
  font-size: 0.8rem;
  min-height: calc(1.25em + .8rem + 2px);
  -webkit-border-radius: .125rem;
  border-radius: $border-radius;
  &::placeholder {
    font-size: $font-size-sm;
    font-weight: 300;
    line-height: 1.25;
    color: $gray-700;
  }
}

.input-label {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.33rem;
  padding-right: 0.25rem;
  font-size: 0.8rem;
  text-align: right;
  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
  &.has-security {
    flex-direction: column;
    padding-top: 0;
    line-height: 1;
    @include media-breakpoint-down(md) {
      flex-direction: row;
      padding-top: 0.33rem;
      line-height: 1.5;
    }
  }
  .security {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1px;
    line-height: 1;
    font-size: 0.625rem;
    margin-left: 0.5rem;
    color: $primary;
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      align-items: center;
      padding-top: 0.1rem;
    }
  }
}
.form-row-special-input {
  display: flex;
  align-items: center;
  padding-top: .33rem;
  height: 100%;
  font-size: $font-size-sm;
}

.hide-caret::after {
  display: none !important;
}

.input-group {
  &-append {
    .btn, .input-group-text {
      font-size: $font-size-base;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &-prepend {
    .btn, .input-group-text {
      font-size: $font-size-base;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &-lg {
    .form-control,
    .input-group-text {
      font-size: $font-size-base;
      border-radius: $border-radius-lg;
    }
  }
  &-text {
    color: $gray-700;
  }
}

input[type="search"]  {
  transition: $transition-base;
  &.invalid {
    border-radius: 0;
    color: red;
    box-shadow: 0 0 .5rem -.375rem red inset;
  }
  &::-webkit-search-cancel-button {
    appearance: none;
    -webkit-appearance: none;
    width: .8rem;
    height: .8rem;
    mask: url("../icons/times.svg") center no-repeat;
    background-color: $gray-700;
    transition: $transition-base;
    &:hover {
      background-color: $primary;
    }

  }
}

.input-standout {
  display: flex;
  margin: 0 -.5rem 1rem;
  padding: 1.5rem 0;
  background-color: $light;
  & .row {
    margin-bottom: 0 !important;
    padding: 0 .5rem;
  }
}

.flex-basis-auto {
  flex-basis: auto !important;
}
.flex-basis-0 {
  flex-basis: 0 !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-menu {
  border-radius: $border-radius;
  border: 0;
  .dropdown-item {
    font-size: $font-size-base;
    &:hover {
      background-color: $primary;
    }
  }
}
.required:after {
  content: "*";
  padding-left: 0.1rem;
  color: $red;
  font-size: $font-size-lg;
}
