.section__container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.settings__container {
  height: calc(100vh - 60px);
  min-height: unset;
  max-height: calc(100vh - 60px);
}

.settings__container__editor {
  height: calc(100vh - 16.6rem);
}

.notification {
  &--open {
    &.settings__container {
      height: calc(100vh - 120px);
      min-height: unset;
      max-height: calc(100vh - 120px);
    }
    .settings__container__editor {
      height: calc(100vh - 20.35rem);
    }

  }
  &--close {
    .settings__container__editor {
      height: calc(100vh - 20.35rem);
    }
  }
}


#cke_settings-editor {
  height: inherit;

  .cke_inner {
    height: inherit;
  }

  #cke_1_contents {
    height: inherit;
  }
}
