.offcanvas.wide {
  --bs-offcanvas-width: 60rem;
}

.offcanvas.extra-wide {
  --bs-offcanvas-width: 100rem;
}

.no-after-caret {
  &::after {
    content:unset!important;
  }
}
.dropdown-menu {
  max-height: 30vh;
  min-height: 3rem;
  .dropdown-item-text {
    font-size: $font-size-sm;
  }
}
