// scss-docs-start import-stack
$primary: #ffbb3e;
$success: #0c6;

// Configuration
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";

// 2. Include any default variable overrides here
@import "variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/dropdown";

// Component specific styles:
@import "components/header";
@import "components/titlebar";
@import "components/pdfPreview";
@import "components/filePreview";
@import "components/privacyModal";
@import "components/calculationModal";

// Page specific styles:
@import "routes/logIn";
@import "routes/registration";
@import "routes/newCustomer";
@import "routes/newDeclaration";
@import "routes/restrictions";
@import "routes/transits";
@import "routes/userProfile";
@import "routes/download";
@import "routes/landing";
@import "routes/settings";
@import "routes/declaration";

// Bootstrap overwrites/commonly used styles:
@import "buttons";
@import "forms";
@import "main";
@import "modal";
@import "tables";
@import "typography";
@import "utilities";

// 3rd party plugin styles:
@import "node_modules/rc-tooltip/assets/bootstrap_white";
@import "3rd-party/rc-tooltip";
@import "3rd-party/react-date-range";
@import "3rd-party/react-select";
@import "3rd-party/react-bootstrap";
@import "3rd-party/react-doc-viewer";

// Font-awesome:
@import "../font-awesome/scss/fontawesome.scss";
@import "../font-awesome/scss/light.scss";
@import "../font-awesome/scss/regular.scss";
@import "../font-awesome/scss/solid.scss";
