// Sizing for circular buttons:
.wh-24 {
  width: 1.5rem;
  height: 1.5rem;
}
.wh-34 {
  width: 2.125rem;
  height: 2.125rem;
}

// Sizing:
.w-1 {width: 1% !important;}
.w-33 {width: 33.33% !important;}
.w-66 {width: 66.66% !important;}

.h-95 {height: 95% !important;}

.pointer {
  cursor: pointer;
}

@media (min-width: 768px) {
  .flex-grow-md-0 {
    flex-grow: 0 !important;
  }
}

.btn-gray-700 {
  color: $gray-700;
  text-transform: initial;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }
}

.hover--yellow {
  &:hover,
  &:focus,
  &.active {
    background-color: $primary;
  }
}

.content-visibility-auto {
  content-visibility: auto;
}

.btn-outline-gray {
    color: black;
    border: 1px solid #d5d5d5;
    &:hover {
        transition: all .2s;
        color: white !important;
        background-color: black;
    }
}
.cursor--not-allowed {
  cursor: not-allowed;
}

//FIXME: .declaration__body .btn-primary is sized smaller than default primary button which is incorrect, this should override the change for now
.font-size-sm {
  font-size: $font-size-sm !important;
}
