html {
  scroll-behavior: smooth;
  @include media-breakpoint-up(xxl) {
    font-size: 18px;
  }
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

main {
  position: relative;
  top: 60px;
  min-height: calc(100vh - 180px);
  &.no-header {
    position: static;
    min-height: 100vh;
  }
  &.aside-open {
    height: 90vh;
    overflow: hidden;
  }
}
