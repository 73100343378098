.title-bar {
  display: flex;
  border-bottom: 1px solid $gray-200;
  padding: 0.5rem 1.5rem;
  width: 100%;
  min-height: 3.75rem;
  background-color: $light;
  z-index: 50;
  @include media-breakpoint-down(md) {
    padding: 0 0.5rem 0 0.75rem;
    .btn {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    h1 {
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    height: 3.75rem;
  }
}
@include media-breakpoint-down(md) {
  .thin-mobile-title-bar {
    flex-wrap: nowrap;
    height: 3.75rem;
  }
}
