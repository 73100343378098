.declaration,
.goods-sidebar {
  &__header {
    background: $gray-100;
    display: grid;

    &--left {
      padding-right: 0;
      border-bottom-style: $border-style;
      border-bottom-color: $nav-tabs-border-color;
      border-bottom-width: $nav-tabs-border-width;
    }

    &--center {
      padding: 0;
      grid-area: headerCenter;
      grid-column: 2/4;

      .nav-tabs {
        font-size: $font-size-base;
        align-items: end;

        @include media-breakpoint-down(xl) {
          font-size: $font-size-sm;
        }

        @include media-breakpoint-down(md) {
          display: block;
          clear: both;
          padding: 0 0.5rem;

          .nav-item {
            width: 50%;
            float: left;

            &:last-child {
              width: 100%;
              margin-bottom: 0.5rem;
            }

            .active {
              border: 1px solid $gray-400;
              border-radius: $border-radius-sm;
              font-weight: 700;
            }
          }
        }

        .nav-link {
          color: $btn-link-color;
        }

        .nav-link.active {
          color: $btn-link-hover-color;
        }
      }
    }

    &--right {
      padding-left: 0;
      border-bottom-style: $border-style;
      border-bottom-color: $nav-tabs-border-color;
      border-bottom-width: $nav-tabs-border-width;
    }
  }

  &__column-heading {
    display: flex;
    background-color: transparent;
    border-bottom: 1px solid $black;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;

    h3 {
      font-size: 0.87rem;
      text-transform: none;
      font-weight: 700;
      color: $gray-900;
    }
  }

  &__body {
    .collapse {

      .card {
        border-radius: $border-radius-sm;
        border-color: $gray-100;
        background-color: $gray-100;
      }
    }

    fieldset {
      border: 1px solid $gray-300;

      .heading {
        margin-bottom: 0;
        border-bottom: 0;
        align-items: center;

        h3 {
          text-transform: none;
          line-height: 2;
        }
      }
    }

    .input-label {
      white-space: normal;
    }

    .btn-primary {
      &.btn-sm {
        background-color: $white;
        border-color: $gray-300;
        color: $black;
        font-size: 0.7rem;
        font-weight: 700;
        text-transform: none;

        &:hover {
          background-color: $black;
          border-color: $black;
          color: $white;
        }
      }

      i.fa {
        color: $primary;
      }
    }
  }
}

.table {
  &.table-declaration {
    thead {
      th {
        font-weight: 700;
      }
    }

    thead,
    tbody {
      .sequence-nr {
        width: 100px;
      }
      tr.invalid-row {
        background-color: rgba(255, 0, 0, 40%);
        --bs-table-striped-bg: rgba(255, 0, 0, 40%);
      }
    }
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  border: 1px dashed $gray-400;
  border-radius: 3px;
  background-color: $gray-100;
  color: $gray-600;
  outline: none;
  transition: border .24s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }
}
