$altYellow: #e4966a;

.landing_index {
  font-family: "Exo", Helvetica, Arial, sans-serif;
  background-image: url('../../../assets/gfx/landing-bg.svg');
  background-size: cover;
  overflow-x: hidden;

  h2 {
    font-size: 1.92rem;
    margin: 3rem 0;

    &:after {
      content: "";
      display: block;
      width: 20%;
      border: 3px solid $yellow;
      border-radius: 0.5rem;
      margin: auto;
    }
  }

  h3 {
    font-size: 1.32rem;
    margin: 3rem 0;
    color: $black;

    &:after {
      content: "";
      display: block;
      width: 20%;
      border: 3px solid $yellow;
      border-radius: 0.5rem;
      margin: auto;
    }
  }

  nav {
    .navbar-brand {
      font-size: 1.5rem;

      &:hover {
        color: $black;
      }
    }

    .nav-item {
      @include media-breakpoint-down(md) {
        padding: 0.85rem 0;
      }

      .nav-link {
        text-decoration: none;
        color: $gray-800;

        &:hover {
          color: $black;
        }
      }
    }
  }

  .hero {
    background-image: url('../../../assets/gfx/intro-img.svg');
    background-position: center;
    height: 36.875rem;
    width: 100%;
    margin-bottom: 5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }

    h1 {
      color: $gray-800;
      text-transform: uppercase;
      font-family: "Lalezar", Helvetica, Arial, sans-serif;
      font-size: 3rem;
      letter-spacing: 0.3rem;
      @include media-breakpoint-down(md) {
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
      }
    }

    &__product-img {
      position: relative;
      bottom: -7rem;
    }

    &__title--sub {
      margin: 1rem 0;
    }
  }

  .integration-map {
    &__title {
      @include media-breakpoint-down(md) {
        margin-bottom: 5rem;
      }
    }

    &__img {
      @include media-breakpoint-down(md) {
        max-width: 16.875rem;
      }
    }

    .lottie {
      width: 6.25rem;
      height: 6.25rem;
      position: absolute;
      @include media-breakpoint-down(md) {
        max-width: 4.375rem;
      }

      &.finland {
        top: -3rem;
        right: 50%;
        transform: translateX(28rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -4rem;
          transform: translateX(10rem);
        }

        &:after {
          position: absolute;
          top: 95%;
          left: -235px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 245px;
          transform: rotate(-15deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 84px;
            left: -34px;
            width: 50px;
            transform: rotate(-28deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.estonia {
        top: 4rem;
        right: 50%;
        transform: translateX(23rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 1rem;
          transform: translateX(10rem);
        }

        &:after {
          position: absolute;
          top: 94%;
          left: -145px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 170px;
          transform: rotate(336deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 55px;
            left: -34px;
            width: 40px;
            transform: rotate(0deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-42%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.latvia {
        top: 6rem;
        right: 50%;
        transform: translateX(31rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(5rem);
        }

        &:after {
          position: absolute;
          top: 85%;
          left: -250px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 270px;
          transform: rotate(-13deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 114px;
            left: -37px;
            width: 160px;
            transform: rotate(83deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.lithuania {
        top: 11rem;
        right: 50%;
        transform: translateX(25rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(-1rem);
        }

        &:after {
          position: absolute;
          top: 61%;
          left: -190px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 204px;
          transform: rotate(-7deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 125px;
            left: -7px;
            width: 190px;
            transform: rotate(55deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.poland {
        top: 16rem;
        right: 52%;
        transform: translateX(31rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 0rem;
          transform: translateX(-5rem);
        }

        &:after {
          position: absolute;
          top: 55%;
          left: -295px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 340px;
          transform: rotate(-2deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 89px;
            left: 55px;
            width: 150px;
            transform: rotate(19deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.romania {
        top: 20rem;
        right: 45%;
        transform: translateX(31rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(5rem);
        }

        &:after {
          position: absolute;
          top: 62%;
          left: -275px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 340px;
          transform: rotate(-4deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 114px;
            left: -37px;
            width: 160px;
            transform: rotate(83deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.great-britain {
        top: -1rem;
        right: 43%;
        transform: translateX(-24rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 0;
          transform: translateX(-5rem);
        }

        &:after {
          position: absolute;
          top: 130%;
          left: 22px;
          content: "";
          display: block;
          border-bottom: 1px solid #e4966a;
          width: 250%;
          transform: rotate(42deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 89px;
            left: 55px;
            width: 150px;
            transform: rotate(19deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.turkey {
        top: 28rem;
        right: 44%;
        transform: translateX(31rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(5rem);
        }

        &:after {
          position: absolute;
          top: 40%;
          left: -190px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 200px;
          transform: rotate(13deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 114px;
            left: -37px;
            width: 160px;
            transform: rotate(83deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.bulgaria {
        top: 24rem;
        right: 54%;
        transform: translateX(31rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(5rem);
        }

        &:after {
          position: absolute;
          top: 29%;
          left: -165px;
          content: "";
          display: block;
          border-bottom: 1px solid $altYellow;
          width: 175px;
          transform: rotate(2deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 114px;
            left: -37px;
            width: 160px;
            transform: rotate(83deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.hungary {
        top: 28rem;
        right: 51%;
        transform: translateX(-4rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: -7rem;
          transform: translateX(5rem);
        }

        &:after {
          position: absolute;
          top: -36%;
          left: 54px;
          content: "";
          display: block;
          border-bottom: 1px solid #e4966a;
          width: 245px;
          transform: rotate(330deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 114px;
            left: -37px;
            width: 160px;
            transform: rotate(83deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.netherlands {
        top: -1rem;
        right: 55%;
        transform: translateX(0rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 0;
          transform: translateX(-5rem);
        }

        &:after {
          position: absolute;
          top: 210px;
          left: -42px;
          content: "";
          display: block;
          border-bottom: 1px solid #e4966a;
          width: 225px;
          transform: rotate(80deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 89px;
            left: 55px;
            width: 150px;
            transform: rotate(19deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.germany {
        top: 14rem;
        right: 43%;
        transform: translateX(-24rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 0;
          transform: translateX(-5rem);
        }

        &:after {
          position: absolute;
          top: 74%;
          left: 90px;
          content: "";
          display: block;
          border-bottom: 1px solid #e4966a;
          width: 251%;
          transform: rotate(4deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 89px;
            left: 55px;
            width: 150px;
            transform: rotate(19deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }

      &.sweden {
        top: -4rem;
        right: 45%;
        transform: translateX(0rem);
        @include media-breakpoint-down(md) {
          font-size: .9rem;
          top: 0;
          transform: translateX(-5rem);
        }

        &:after {
          position: absolute;
          top: 9rem;
          left: .5rem;
          content: "";
          display: block;
          border-bottom: 1px solid #e4966a;
          width: 7rem;
          transform: rotate(71deg);
          z-index: -1;
          @include media-breakpoint-down(md) {
            top: 89px;
            left: 55px;
            width: 150px;
            transform: rotate(19deg);
          }
        }

        span {
          top: 50%;
          right: 50%;
          position: absolute;
          transform: translateX(50%) translateY(-41%);
          z-index: 1;
          @include media-breakpoint-down(md) {
            top: 2.188rem;
          }
        }
      }
    }
  }

  .privacy-policy {
    h2 {
      text-align: center;
    }
  }

  footer {
    background-image: url('../../../assets/gfx/footer-bg.svg');
    background-position: top right;
    background-size: cover;
    padding: 1rem 0;
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    button, a {
      border: none;
      background-color: transparent;
      text-decoration: none;

      &:hover {
        color: $black;
      }
    }

    .icon {
      margin-right: 1rem;
    }
  }
}
