.pdf-preview-modal {
  .modal-body {
    padding: 0;
    overflow: auto;
  }
  .modal-content {
    @include media-breakpoint-up(sm) {
      left: 6%;
      margin: 0;
      width: 88%;
      top:1.75rem;
      height: 94%;
    }
  }
}

.pdf-preview {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 90%;
  canvas {
    border: 1px solid silver;
  }
}
