.download__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #ffbb3e 0%,#1a1b1e 92%);
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    content: '';
    background: url("../../gfx/login-background.png") no-repeat;
    z-index: 100;
  }
  .download__card {
    border-radius: $border-radius;
    padding: 1rem;
    padding-bottom: 1.5rem;
    width: 30vw;
    background-color: $white;
    box-shadow: $box-shadow;
    z-index: 101;
    @include media-breakpoint-down(md) {
      width: 90vw;
    }
  }
  h1 {
    font-weight: 700;
  }
  .export-notification {
    display: flex;
    justify-content: center;
    font-size: $font-size-lg;

  }
  .download-link {
    font-size: $sub-sup-font-size;
    display: flex;
    justify-content: center;
  }
}
