.modal-backdrop.show {
  opacity: 0.2;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0);
  transition-duration: 0.25s;
  z-index: 1000;
  visibility: hidden;

  &--backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1031;
    left: 0;
    opacity: 0.5;
    background-color: rgba($black, 5);
  }

  &.active {
    background-color: rgba($black, 0.2);
    visibility: visible;
  }
}

.modal-content {
  position: fixed;
  top: 25%;
  left: 0;
  margin: 0.5rem;
  border-radius: $border-radius-lg;
  width: calc(100% - 1rem);
  box-shadow: $box-shadow;
  z-index: 2000;
  @include media-breakpoint-up(sm) {
    left: calc(50% - 240px);
    margin: 0;
    width: 480px;
  }

  .modal-header {
    background-color: $gray-200;
    padding-top: 0;
    padding-bottom: 0;

    .modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .modal-content {
    width: 100%;
  }

  .modal-footer {
    border-top: 1px solid $gray-200;
    padding: 1rem;
  }
}

.backdrop-with-modal {
  opacity: 0.1 !important;
  z-index: 1050;
}

.feedback-modal {
  .modal-content {
    top: 3vh;
  }

  @media only screen and (max-width: 768px) {
    .modal-content {
      height: 90vh;
      top: 3vh;
      left: initial;
      margin: 0;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .modal-content {
      height: 90vh;
      top: 3vh;
      margin: 0;
    }
  }

  .modal-body {
    height: auto;
    overflow-y: auto;
  }
}

.import-modal {
  .modal-content {
    left: 0;
    right: 0;
    width: 90vw !important;
    margin-left: 5%;
    margin-right: 5%;
  }
}
.export-modal {
  .modal-content {
    top: 3vh;
    left: initial;
    height: 90vh;
    width: 62.5vw;
  }

  .modal-body {
    height: auto;
    overflow-y: auto;
  }
}

.customs-modal {
  .modal-content {
    //width: auto;
    top: auto;
    left: calc(50% - 240px);
    height: auto;
    max-height: 90vh;
    margin: auto;
  }

  .modal-body {
    height: auto;
    overflow-y: auto;
  }
}


.preferences-modal {
  .modal-content {
    top: 3vh;
  }

  @media only screen and (max-width: 768px) {
    .modal-content {
      width: 100vw;
    }
  }


  .modal-body {
    height: auto;
    overflow-y: auto;
  }
}

.modal-to-size {
  .modal-content {
    left: unset;
    width: 100%;
    max-width: inherit;
  }
}
