.btn {
  -webkit-appearance: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: $font-size-sm;
  border-radius: $border-radius-lg;
  &.btn-link.no-underline {
    text-decoration: none !important;
    text-transform: initial;
  }
  &.no-text-transform {
    text-transform: initial;
  }
  &:hover,
  &:focus,
  &.active {
    i {
      text-decoration: none !important;
    }
  }
  &.btn-secondary {
    border-color: lighten($dark, 10%);
    &:hover,
    &:focus,
    &.active {
      background-color: lighten($dark, 10%);
    }
  }
  &-lg {
    font-size: $font-size-base;
  }
  &-secondary {
    background-color: $secondary;
  }
}

.btn-round {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .75rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  i {
    line-height: inherit;
    vertical-align: middle;
  }
}
