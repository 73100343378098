aside {
  position: fixed;
  top: 0;
  right: -95%;
  width: 95%;
  max-width: 1200px;
  height: 100vh;
  background-color: $white;
  transition-duration: 0.25s;
  box-shadow: $box-shadow;
  overflow: hidden;
  z-index: $zindex-modal;
  @include media-breakpoint-down(md) {
    right: -100%;
    width: 100%;
  }
  &.active {
    right: 0;
  }
  .aside__header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-400;
    padding: 1rem 1.75rem 1rem 1rem;
    height: 60px;
    width: 95%;
    max-width: 1200px;
    background-color: $white;
    z-index: 1100;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .aside-main {
    position: relative;
    top: 60px;
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-400;
    padding: .5rem 1rem;
    height: 37px;
    min-height: 37px;
    background-color: $light;
    @include media-breakpoint-down(sm) {
      font-size: .8rem;
      height: 3.5rem;
      min-height: 3.5rem;
    }
  }
  .section__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 0;
    width: 100%;
    overflow: hidden;
    &.active {
      min-height: fit-content;
      height: auto;
      overflow: initial;
      border-bottom: 1px solid $gray-400;
    }
    .heading {
      margin-bottom: 0.25rem;
    }
  }
}

.log-status {
  width: 1%;
  min-width: initial !important;
}

tr.log-row td {
  padding: 0 !important;
  &.log-row__messages {
    .log-row__messages-container {
      display: flex;
      padding: 1rem;
      border-bottom: 1px solid darken($light, 15%);
      background-color: $light;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      span {
        white-space: normal;
        word-break: break-word;
      }
      .log-row__messages-column {
        display: flex;
        flex-direction: column;
        padding-left: .5rem;
        padding-right: .5rem;
        width: 20%;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
    }
    &.first {
      padding: .5rem 1rem 1rem;
    }
    &.last {
      padding: 1rem 1rem .5rem;
      .log-row__messages-container {
        border-bottom: none;
      }
    }
  }
}

.table.fixed-columns {
  td.fixed-width-, th.fixed-width- {
    &sm {
      min-width: 4.8rem;
      max-width: 4.8rem;
      width: 4.8rem;
    }
    &sm-l {
      min-width: 6rem;
      max-width: 6rem;
      width: 6rem;
    }
    &md-s {
      min-width: 8rem;
      max-width: 8rem;
      width: 8rem;
    }
    &md {
      min-width: 10.5rem;
      max-width: 10.5rem;
      width: 10.5rem;
    }
    &lg {
      min-width: 12rem;
      max-width: 12rem;
      width: 12rem;
    }
    &sm, &sm-l, &md-s, &md, &lg {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.transit-sidebar {

  .card {
    border-radius: $border-radius-sm;
    border-color: $gray-100;
    background-color: $gray-100;
  }
}
