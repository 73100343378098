@font-face {
  font-family: "Lalezar";
  src: url("../../assets/fonts/Lalezar-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Exo";
  src: url("../../assets/fonts/Exo-Regular.ttf") format("truetype");
}

h1 {
  color: $primary;
  white-space: nowrap;
}
h2 {
  text-transform: uppercase;
}
h3 {
  text-transform: uppercase;
  color: $gray-600;
  line-height: 1.5;
}
h5 {
  text-transform: uppercase;
  color: $gray-500;
  line-height: 1.5;
}

small {
  display: inline-block;
  font-size: $font-size-sm;
}
.strikethrough {
  text-decoration-line: line-through;
}
