@import "../../font-awesome/scss/_variables.scss";

header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: $secondary;
  box-shadow: 0 -.65rem .4rem  1rem rgba($black, .25);
  transition-duration: 0.25s;
  z-index: 500;

  .brand {
    margin-right: 0.625rem;
    min-width: 72px;
    background-color: $primary;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: $dark;
      font-size: 1.825rem;
      text-decoration: none;

      &:hover,
      &:focus,
      &.active {
        color: $black;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15) inset;
      }
    }
  }
}

.header__user {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  color: $white;
  word-wrap: none;
  white-space: nowrap;
  font-size: $font-size-base;
  text-transform: none;
  line-height: 1.25;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .header__user-company {
    color: rgba(255, 255, 255, 0.5);
    font-size: $font-size-sm;
    text-transform: none;
    text-align: left;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

nav.header__nav a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  height: 100%;
  color: $white;
  font-size: 0.8rem;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &.active {
    color: $primary;
  }
}

.header__language-button.select {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  width: fit-content;
  transition-duration: 0.25s;
  padding: 0 1.875rem;
  &:active,
  &:focus,
  &:hover {
    background-color: $black;
  }
  .select__control {
    padding: 0;
    color: $white;
    &:hover {
      background-color: transparent;
    }
    &--is-focused {
      border: none !important;
      .select__placeholder,
      .select__single-value {
        color: $white;
      }
    }
    .select__value-container {
      color: $white;
      width: 2rem;
      .select__placeholder,
      .select__single-value {
        color: $white;
        font-size: .875rem;
      }
    }
    .select__indicators .select__indicator {
      background-color: $white;
    }
  }
  .select__menu {
    margin-top: .2rem;
    right: 0;
    border-radius: 0;
    .select__menu-list {
      margin: 0 auto;
      width: 75%;
      box-shadow: $box-shadow;
    }
  }
}

.header__profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-left: 2px solid $gray-800;
  border-radius: 0;
  padding: 0 1.875rem 0;
  height: 100%;
  background-color: $dark;

  &:hover,
  &:focus,
  &.active {
    background-color: $black;
  }
}

@include media-breakpoint-down(md) {
  .header__language-button.select {
    width: 25%;
  }
  .header__profile-button {
    width: 50%;
    height: 60px;
  }
}

.header__mobile-menu {
  position: fixed;
  top: -100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 59px);
  width: 100vw;
  background-color: $dark;
  transition-duration: 0.25s;
  z-index: 1040;
  .header__nav-header {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    min-height: 60px;
    margin-bottom: 1rem;
  }
  &.open {
    top: 59px;
  }

  nav.header__nav {
    height: 100%;
  }

  nav.header__nav a {
    height: auto;
    margin: 1rem 0;
  }
}

// Heade icons:
.transits-icon {
  display: inline-block;
  background: url("../../icons/transits-icon.svg") no-repeat center/contain;
  width: 39px;
  height: 24px;
}

.transit-operations-icon {
  display: inline-block;
  background: url("../../icons/transits-icon.svg") no-repeat center/contain;
  width: 39px;
  height: 24px;
}
.transit-audit-icon {
  display: inline-block;
  background: url("../../icons/audit.svg") no-repeat center/contain;
  width: 39px;
  height: 24px;
}

.customers-icon {
  display: inline-block;
  background: url("../../icons/customers-icon.svg") no-repeat center/contain;
  width: 30px;
  height: 24px;
}
.company-icon {
  display: inline-block;
  background: url("../../icons/customers-icon.svg") no-repeat center/contain;
  width: 30px;
  height: 24px;
}

.settings-icon {
  display: inline-block;
  background: url("../../icons/settings-icon.svg") no-repeat center/contain;
  width: 26px;
  height: 24px;
}

.restrictions-icon {
  display: inline-block;
  background: url("../../icons/restrictions.svg") no-repeat center/contain;
  width: 39px;
  height: 24px;
}

.audit-icon {
  display: inline-block;
  background: url("../../icons/audit.svg") no-repeat center/contain;
  width: 26px;
  height: 24px;
}
.privacy-icon {
  display: inline-block;
  background: url("../../icons/scroll.svg") no-repeat center/contain;
  width: 26px;
  height: 24px;
}
.notification-icon {
  display: inline-block;
  background: url("../../icons/bell.svg") no-repeat center/contain;
  width: 26px;
  height: 24px;
}

a:hover,
.active {
  .transits-icon, .customers-icon, .settings-icon, .restrictions-icon, .audit-icon, .company-icon, .privacy-icon, .notification-icon {
    background: none;
    background-color: $primary;
  }

  .transits-icon {
    mask: url("../../icons/transits-icon.svg") no-repeat center/contain;
  }

  .customers-icon {
    mask: url("../../icons/customers-icon.svg") no-repeat center/contain;
  }

  .company-icon {
    mask: url("../../icons/customers-icon.svg") no-repeat center/contain;
  }

  .settings-icon {
    mask: url("../../icons/settings-icon.svg") no-repeat center/contain;
  }

  .restrictions-icon {
    mask: url("../../icons/restrictions.svg") no-repeat center/contain;
  }
  .audit-icon {
    mask: url("../../icons/audit.svg") no-repeat center/contain;
  }
  .privacy-icon {
    mask: url("../../icons/scroll.svg") no-repeat center/contain;
  }
  .notification-icon {
    mask: url("../../icons/bell.svg") no-repeat center/contain;
  }
}
.notification {
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: $gray-900;
  color: $white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  & .alert {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    z-index: 10;
  }
}
