.new-customer__container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: calc(100vh - 60px - 3.75rem); // remaining = max - header - (topbar without navigation bar)

  &--notification-open {
    height: calc(100vh - 120px - 7.5rem); // remaining = max - header with notification bar - topbar
  }
}
